import React from 'react'
import './ProjectGrid.css'
import { useNavigate } from 'react-router-dom';

interface ProjectProps {
  projectName: string;
  image: string;
  url: string;
}
const projectsData = [
  { projectName: 'Licence Plate Reader', image: 'images/licenceplate.jpg', url: 'licenceplate-reader'},
  { projectName: 'Project 2', image: 'images/mixer100.png', url: '1' },
  { projectName: 'Project 3', image: 'images/mixer100.png', url: '2' },
  { projectName: 'Project 2', image: 'images/mixer100.png', url: '3' },
  { projectName: 'Project 3', image: 'images/mixer100.png', url: '4' },
  { projectName: 'Project 2', image: 'images/mixer100.png', url: '5' }
  

];
function Projects({ projectName, image, url }: ProjectProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/project/${url}`);
  };    
  return (
    <div className='project-container' onClick={handleClick}>
        <div className='project-box'>
        <img src={image} alt={projectName} className='project-thumbnail' />
        <h1 className='project-title'>{projectName}</h1>
        </div>
    </div>
  );

}

function ProjectsList() {
  return (
    <div className='project-container'>
      {projectsData.map((project, index) => (
        <Projects key={project.url} projectName={project.projectName} image={project.image} url={project.url} />
      ))}
    </div>
  );
}

export default ProjectsList