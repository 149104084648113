import React, { useState } from 'react';
import axios from 'axios';
import InputFileUpload from 'components/buttons/UploadFile';
import './LicensePlateReader.css';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const loadImageBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

function LicensePlateReader() {
  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(null);
  const [processedImage, setProcessedImage] = useState<string | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const image = await loadImageBase64(file);
        setFileContent(image);
        console.log('apiUrl', apiUrl);
        const base64Image = (image as string).replace(/^data:image\/\w+;base64,/, '');

        const response = await axios({
          method: 'POST',
          url: `${apiUrl}/predict`,
          data: JSON.stringify({ image: base64Image }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        setProcessedImage(`data:image/jpeg;base64,${response.data.processed_image}`);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div className='project'>
      <h1>License Plate Reader</h1>
      <p>This is a school project that me and a classmate made for a Machine Learning exam.</p>
      <video className='video' width="320" height="240" autoPlay loop controls src='/images/mig.mp4'/>
      <br />
      <InputFileUpload onChange={handleFileChange} />

      {/* Display the processed image */}
      {processedImage && (
        <div>
          <h2>Processed Image:</h2>
          <img src={processedImage} alt="Processed file" />
        </div>
      )}
    </div>
  );
}

export default LicensePlateReader;